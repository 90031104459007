import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchFarmStore = defineStore('searchFarmStore', {
  state: () => ({
    Farm_agisId: null as string | null,
    Farm_berNo: null as number | null,
    Farm_tvdId: null as number | null,
    Farm_cantonalId: null as string | null,
    Farm_farmTypeId: null as number | null,
    Farm_status: null as number | null,
    Farm_cantonId: null as number | null,
    useTerms: true,
    items: [
      { key: 'Farm_agisId', operation: ':', itemType: 'string', isList: true },
      { key: 'Farm_berNo', operation: ':' },
      { key: 'Farm_tvdId', operation: ':' },
      { key: 'Farm_cantonalId', operation: ':' },
      { key: 'Farm_farmTypeId', operation: ':' },
      { key: 'Farm_status', operation: ':', itemType: 'number' },
      { key: 'Farm_cantonId', operation: ':' }
    ] as TermItemDefinition[]
  })
})
